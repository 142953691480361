import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Button, message } from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import DragNDrop from './DragnDrop';
import { COURSE_CONTENT_API_LIST } from '../../../constants/CollegeDekho'
import { useFetchData } from "../../../hooks/";
import TranslateModal from "../TranslateModal";
import { CONTENT_TYPE_ID } from "../../../constants/Settings";
const CoursePageComponentListing = (props) => {

  const { courseID, courseVernacularID } = useParams();
  const { courseMasterPageID } = useParams();
  let url = COURSE_CONTENT_API_LIST["COURSE_PAGE_COMPONENT_LISTING"].replace("#id#", courseVernacularID)
  url = url.replace("#id1#", courseMasterPageID)
  const [data, loading, error] = useFetchData(url)
  const [dataSource, setDataSource] = useState([]);
  const [extraDetails, setExtraDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
    const [courseVernacularId, setCourseVernacularId] = useState(0)
  const dashboardTypeDetailURL = COURSE_CONTENT_API_LIST["COURSE_DETAIL_URL"].replace("#id#", courseID)
  const [breadCrumb, loadingDashboardTypeDetail, errorDashboardTypeDetail] = useFetchData(dashboardTypeDetailURL)

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (data) {
        setDataSource(data.results)
        setExtraDetails(data.extra_details)

      }
    }


    return () => mounted = false;
  }, [data])

const onUpdateVernacularId=(id)=>{
  setIsModalOpen(true)
  setCourseVernacularId(id)
}
  return (
  <>
  {isModalOpen&&<TranslateModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} sourceId={courseVernacularId} courseContentId={CONTENT_TYPE_ID?.componentCourseContentMasterPage}/>}
      <Row>
      {error ? message.error(error) : null}
      <Col span={24}>
        <NavBreadCrumb 
        page={extraDetails?.master_page?.name} 
        breadCrumb={breadCrumb}
        courseVernacularID={extraDetails?.course_content_vernacular} 
        />
        <div className="gx-pt-4">
          <div className="ant-card ant-card-bordered gx-card-widget">
            <div className="ant-card-body">
              <h2 className="gx-mb-0">
                {extraDetails?.course_content_vernacular?.name}, ({extraDetails?.master_page?.name})
              </h2>
            </div>
          </div>
        </div>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Link to={{ pathname: `/course/${courseID}/vernacular/${courseVernacularID}/page/${courseMasterPageID}/component/create/`, locationData: extraDetails }}>

              <Button>
                <span>Add New Component</span>
              </Button>
            </Link>


          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <DragNDrop dataSource={dataSource} setDataSource={setDataSource} setCourseVernacularId={onUpdateVernacularId}/>
      </Col>
    </Row>
    </>
  );
};

export default CoursePageComponentListing;
