import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Col, Button, Table, message, Modal, Select, Checkbox } from "antd";
import { FormOutlined } from '@ant-design/icons';
import { get } from '../../../services';
import { getErrors, getLanguage } from '../../../util/helpers';
import { COMMON_SUCCESS_MESSAGE, COURSE_CONTENT_API_LIST, DATE_TIME_FORMAT_LISTING } from '../../../constants/CollegeDekho'
import { API_URL_LIST } from '../../../constants/CollegeDekho';
import { post } from '../../../services';
import { CONTENT_TYPE_ID } from '../../../constants/Settings';
import moment from 'moment';
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';


const CourseVernacularListing = () => {

    const [isModalOpen, setIsModalOpen] = useState();
    const [selectedValue, setSelectedValue] = useState([]);
    const [translateIsLoading, setTranslateIsLoading] = useState(false);
    const [translateError, setTranslateError] = useState(null);
    const [translateSuccessMessage, setTranslateSuccessMessage] = useState(null);
    const [makeCourseLive, setMakeCourseLive] = useState(true)
    const [extraDetails, setExtraDetails] = useState()
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [vernacularCourseList, setVernacularCourseList] = useState([])
    const [courseVernacularId, setCourseVernacularId] = useState(0)
    const { courseID } = useParams();
    const languageOptions = getLanguage()



    const [author, setAuthor] = useState(null)
    const [newTemplateVisible, setNewTemplateVisible] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const url = COURSE_CONTENT_API_LIST["COURSE_VERNACULAR_DETAIL_POST_URL"].replace("#id#", courseID);

    const openModal = (recordId) => {
        setIsModalOpen(true);
        setCourseVernacularId(recordId)
    }

    const authorPermission = (record) => {
        if (record.permissions.includes("change_author")) {
        newTemplateModel(record?.id)
        }
        else {
            alert("You are not allow to change author")
        }
    }
    const onHandleTranslateCourse=(id)=>{
    openModal(id);
}
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Short Name',
            dataIndex: 'short_name',
        },
        {
            title: 'Language',
            dataIndex: 'language',
            render: (text, record) => (record.language.name),
        },
        {
            title: 'Last Modified On',
            dataIndex: 'modified_on',
            render: (text, record) => (
                <span>
                    {moment(record.modified_on).format(DATE_TIME_FORMAT_LISTING)}
                </span>
            ),
        },
        {
            title: 'Last Modified By',
            dataIndex: 'modified_by',
            render: (text, record) => (
                <>
                    <span>
                        {record.modified_by?.name}
                    </span>
                    <p style={{ color: 'blue', cursor: 'pointer' }} onClick={() => authorPermission(record)}>
                        {record.author?.name ? record.author?.name : "change author"}
                    </p>
                </>
            ),
        },
        {
            title: 'Auto Translate',
            dataIndex: 'auto_translate',
            render: (text, record) => (
                <span>
                    {record.auto_translate ? "True" : "False"}
                </span>
            ),
        },
        {
            title: 'FAQ',
            key: 'faq',
            render: (text, record) => (
                <span>
                    <span className="gx-link">
                        <Link to={`/course/${courseID}/vernacular/${record?.id}/faqs/${CONTENT_TYPE_ID.course}/`}>
                            <FormOutlined />
                        </Link>
                    </span>
                </span>

            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <span className="gx-link"><Link to={'/course/' + courseID + '/vernacular/' + record.id + '/update/'}> <FormOutlined /></Link></span>

                </>

            ),
        },


        {
            render: (text, record) => (
                <Button type="secondary" onClick={() => onHandleTranslateCourse(record?.id)} style={{ marginTop: 10 }}>
                    Translate Course
                </Button>

            ),
        },

    ]

    const newTemplateModel = (courseVernacularID) => {
        setCourseVernacularId(courseVernacularID)
        setNewTemplateVisible(true);
    }
    const postData = () => {
        const url = API_URL_LIST["COMMON_AUTHOR_URL"].replace("#id2#", courseVernacularId).replace("#id1#", 960)
        post(url, { author_id: author?.value })
            .then(function (response) {
                setNewTemplateVisible(false);
                message.success(COMMON_SUCCESS_MESSAGE);
                setRefresh(true)
            })
            .catch(crudErrorHandler)
            .finally(() => {
                //setDisabledSave(false)
            });

    }
    const handleOk = (e) => {
        postData()
    }

    const handleCancel = (e) => {
        setNewTemplateVisible(false);
    }

    useEffect(() => {
        let mounted = true;

        setLoading(true);
        get(url)
            .then(data => {
                if (mounted) {
                    setVernacularCourseList(data.data.results);
                    setExtraDetails(data.data.extra_details)
                    setLoading(false);
                    setError(null);
                }
            })
            .catch(function (response) {
                const err = getErrors(response)
                setError(err.allError);
                console.log(err)

            });

        return () => mounted = false;
    }, [])

    const handleModalClose = (e) => {
        setIsModalOpen(false)
        setTranslateError()
        setSelectedValue([])
        setTranslateSuccessMessage()

    }

    const handleSelectChange = (e) => {
        // const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedValue(e);

    };

    const crudErrorHandler = (error) => {
        const errors = getErrors(error)
        if (errors.allError.length > 0) {
            setTranslateError(errors.allError);
        }
        else {
            setTranslateError("Some unknown error occured in translate API.");
        }
    }

    const handleApiCall = (e) => {
        setTranslateIsLoading(true);
        setTranslateError()
        if (!selectedValue) {
            setTranslateError("No language selected for translation.");
            setTranslateIsLoading(false);
            return false
        };

        const url = API_URL_LIST.TRANSLATE_API


        const payload = {
            "target_language": selectedValue,
            "source_object_id": courseVernacularId,
            "content_type_id": `${CONTENT_TYPE_ID.course}`,
            "auto_live": makeCourseLive ? 1 : 0
        }

        post(url, payload)
            .then(function (response) {
                setTranslateSuccessMessage(response?.data?.message);
            })
            .catch(crudErrorHandler)
            .finally(() => {
                setTranslateIsLoading(false);
            });
    }

    const onChange = (e) => {
        setMakeCourseLive(e.target.checked)
    };

    return (
        <>
            <Modal
                title="Translate Course Page"
                visible={isModalOpen}
                okText="Translate"
                onOk={handleApiCall}
                confirmLoading={translateIsLoading}
                onCancel={handleModalClose}>
                <h3>Select Language</h3>

                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    // defaultValue={['a10', 'c12']}
                    onChange={handleSelectChange}
                    options={languageOptions}
                    value={selectedValue}

                />
                <Checkbox style={{ marginTop: 10 }} onChange={onChange} value={makeCourseLive} defaultChecked={true}>Make Course Live</Checkbox>

                {translateError && <div style={{ color: 'red' }}>{translateError}</div>}  {/* Display error message */}
                {translateSuccessMessage && <div>{translateSuccessMessage}</div>}

            </Modal>

            {/* <Row> */}
            {error ? message.error(error) : null}

            <Col span={24} style={{ textAlign: 'right' }}>
            <NavBreadCrumb breadCrumb={extraDetails}/>

                <Link to={'/course/' + courseID + '/vernacular/create/'}>
                    <Button type="primary">
                        <span>Add New Vernacular Course</span>
                    </Button>
                </Link>

            </Col>

            <Col span={24}>
                <Table loading={loading} rowKey="id" columns={columns} dataSource={vernacularCourseList} pagination={false} />
            </Col>

            {/* </Row> */}

        </>
    )
}

export default CourseVernacularListing
