import moment from 'moment';
import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { COURSE_CONTENT_API_LIST, COMMON_SUCCESS_MESSAGE, DATE_TIME_FORMAT_LISTING, API_URL_LIST } from "../../../constants/CollegeDekho";
import { getCourseMasterPageList } from "../../../services/MasterPage/MasterPage";
import { Divider, Row, Col, Alert, Modal, Table, Button, message } from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import DebounceSelect from '../../../components/DebounceSelect'
import { post, get } from '../../../services/';
import { getErrors } from "../../../util/helpers";
import { FormOutlined } from "@ant-design/icons";
import { CONTENT_TYPE_ID } from '../../../constants/Settings';
import { useFetchData } from '../../../hooks';
import SecondaryButton from '../SecondaryButton';
import TranslateModal from '../TranslateModal';




const CoursePageListing = () => {

    const { courseID, courseVernacularID } = useParams();

    const url = COURSE_CONTENT_API_LIST["COURSE_PAGE_LISTING"].replace("#id#", courseVernacularID)
    const [translateIsLoading, setTranslateIsLoading] = useState(false);

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const [newTemplateVisible, setNewTemplateVisible] = useState(false)

    const [masterPage, setMasterPage] = useState(null)
    const [isError, setIsError] = useState(false)
    const [messageText, setMessageText] = useState("")
    const [refresh, setRefresh] = useState(false)
    const dashboardTypeDetailURL = COURSE_CONTENT_API_LIST["COURSE_DETAIL_URL"].replace("#id#", courseID)
    const [breadCrumb, loadingDashboardTypeDetail, errorDashboardTypeDetail] = useFetchData(dashboardTypeDetailURL)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [courseVernacularId, setCourseVernacularId] = useState(0)


    useEffect(() => {
        let mounted = true;
        const getData = async () => {
            setLoading(true);
            await get(url)
                .then(function (data) {
                    if (mounted) {
                        setData(data.data);
                        setLoading(false);
                        setError(null);

                    }

                })
                .catch(function (errors) {
                    const err = getErrors(errors)
                    setError(err.allError);
                });
        }

        if (url) {
            getData();
        }

        return () => mounted = false;
    }, [url, refresh])


    const newTemplateModel = (e) => {
        setNewTemplateVisible(true);
        setIsError(false)
    }
    const crudErrorHandler = (error) => {
        showError(error);
    }
    const postData = () => {
        post(url, { master_page: masterPage.value, object_status: 1, course_content: courseID, course_content_vernacular: courseVernacularID })
            .then(function (response) {
                setNewTemplateVisible(false);
                message.success(COMMON_SUCCESS_MESSAGE);
                setRefresh(true)
            })
            .catch(crudErrorHandler)
            .finally(() => {
                //setDisabledSave(false)
            });

    }
    const handleOk = (e) => {
        //setNewTemplateVisible(false);
        if (!masterPage) {
            setIsError(true)
            setMessageText("Please choose template")
            return;
        }
        postData()
    }

    const handleCancel = (e) => {
        setNewTemplateVisible(false);
    }

    const showError = (err) => {
        const errors = getErrors(err)
        setIsError(true)
        setMessageText(errors.allError.join())
    }
    const columns = [{
        title: 'ID',
        dataIndex: 'id',
    
    },
    {
        title: 'Name',
        key: 'name',
        render: (text, record) => (
            <span>
                {record.master_page?.name}
            </span>
        ),
    },
    {
        title: 'H1',
        dataIndex: 'h1',
    
    },
    {
        title: 'Title',
        dataIndex: 'title',
    
    },
    
    {
        title: 'Order',
        key: 'order',
        render: (text, record) => (
            <span>
                {record.master_page?.order}
            </span>
        ),
    
    },
    {
        title: 'Status',
        key: 'status',
        render: (text, record) => (
            <span>
                {record.object_status.name}
            </span>
        ),
    
    },
    {
        title: 'Last Modified On',
        dataIndex: 'modified_on',
        render: (text, record) => (
            <span>
                {moment(record.modified_on).format(DATE_TIME_FORMAT_LISTING)}
            </span>
        ),
    },
    {
        title: 'FAQ',
        dataIndex: 'faq',
        render: (text, record) => (
            <span>
                <Link to={{
                    pathname: `/course/${record?.course_content?.id}/vernacular/${record?.course_content_vernacular?.id}/faqs/${CONTENT_TYPE_ID.course}/`,
                    search: `?page_content_type_id=${CONTENT_TYPE_ID.courseContentMasterPage}&page_object_id=${record.id}`
                }}>
                    <FormOutlined />
                </Link>
            </span>
        ),
    },
    {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
            <span>
                <Divider type="vertical" />
                <span className="gx-link">
                    <Link to={`/course/${record.course_content?.id}/vernacular/${record.course_content_vernacular?.id}/page/${record.id}/update/`}> Edit/SEO</Link>
                </span>
                <Divider type="vertical" />
                <span className="gx-link">
                    <Link to={{ pathname: `/course/${record.course_content?.id}/vernacular/${record.course_content_vernacular?.id}/page/${record.id}/component`, locationData: { "course": { "name": record.course_content?.name }, "master_page": { "name": record.master_page?.name } } }}>
                        Components
                    </Link>
                </span>
            </span>
        ),
    },{
        render: (text, record) => (
            <>
            <SecondaryButton onClick={() => onUpdateVernacularId(record?.id)} title={'Translate'}/>
            </>
          ), 
    }
    ];
    const handleApiCall = (e) => {
        setTranslateIsLoading(true);
       
    
        const url = API_URL_LIST.TRANSLATE_API;
    
        const payload = {
        //   target_language: selectedValue,
        //   source_object_id: sourceId,
        //   content_type_id: courseContentId,
        };
    
        post(url, payload)
          .then(function (response) {
            // setTranslateSuccessMessage(response?.data?.message);
          })
          .catch(crudErrorHandler)
          .finally(() => {
            setTranslateIsLoading(false);
          });
      };
      const onUpdateVernacularId=(id)=>{
        setIsModalOpen(true)
        setCourseVernacularId(id)
      }
    return (
        <>
          {isModalOpen&&<TranslateModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} sourceId={courseVernacularId} courseContentId={CONTENT_TYPE_ID?.courseContentMasterPage}/>}

            {error ? message.error(error) :
                <Row>
                    <Col span={24}>
                        <NavBreadCrumb 
                        breadCrumb={breadCrumb} 
                        courseVernacularID={data?.extra_details?.course_content_vernacular} 
                        />
                        <div className="gx-pt-4">
                            <div className="ant-card ant-card-bordered gx-card-widget">
                                <div className="ant-card-body">
                                    <h2 className="gx-mb-0">{data?.extra_details?.course_content_vernacular && data.extra_details.course_content_vernacular?.name} Master Pages ({data?.extra_details?.course_content_vernacular && data.extra_details.course_content_vernacular?.language?.name})</h2>

                                    <Modal
                                        title="New Page"
                                        visible={newTemplateVisible}
                                        onOk={handleOk}
                                        onCancel={handleCancel}
                                    >
                                        {isError &&
                                            <Alert type="error" message={messageText} banner />
                                        }

                                        <DebounceSelect

                                            mode="single"
                                            name="master-page"
                                            placeholder="Select Master Page"
                                            fetchOptions={getCourseMasterPageList}
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            onChange={(newValue) => {
                                                setMasterPage(newValue);
                                                console.log(newValue);
                                            }}
                                        />
                                    </Modal>
                                </div>
                            </div>
                        </div>
                        <Row>
                            <Col span={18}>
                            </Col>
                            <Col span={6} style={{ textAlign: 'right' }}>

                                <Button type="primary" onClick={newTemplateModel}>Add New Page</Button>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={24}>

                    </Col>

                    <Col span={24}>
                        <Table className="gx-table-responsive" loading={loading} columns={columns} dataSource={data?.results} pagination={true} />
                    </Col>

                </Row>
            }
        </>
    );
};

export default CoursePageListing;
