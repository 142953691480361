import React, { useState } from 'react'
import { useSelector } from "react-redux";
import { Row, Col, Button, message } from "antd";
import { Link, useParams } from 'react-router-dom';
import LatestUpdateListComponent from "./LatestUpdateListComponent";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import { API_URL_LIST } from '../../../constants/CollegeDekho';
import { getErrors } from '../../../util/helpers';
import { post } from '../../../services';
import { COURSE_CONTENT_API_LIST } from '../../../constants/CollegeDekho';
import { useFetchData } from '../../../hooks';
import { CONTENT_TYPE_ID } from '../../../constants/Settings';
import TranslateModal from '../../courseDetail/TranslateModal';


const LatestUpdateListing = () => {

	const pathname = useSelector(({ common }) => common.pathname);
	const dashboardType = pathname.includes("institute") ? "institute" : "course";
	const [translateIsLoading, setTranslateIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
    const [courseVernacularId, setCourseVernacularId] = useState(0)

	const { ID, vernacularID } = useParams();
	const [extraDetails, setExtraDetails] = useState(null);
	const [error, setError] = useState(null)
	const handleApiCall = (e) => {
		setTranslateIsLoading(true);
	
		const url = API_URL_LIST.TRANSLATE_API;
	
		const payload = {
		  // target_language: selectedValue,
		  source_object_id: e,
		  // content_type_id: courseContentId,
		};
	
		post(url, payload)
		  .then(function (response) {
			message.success(response?.data?.message);
		  })
		  .catch(crudErrorHandler)
		  .finally(() => {
			setTranslateIsLoading(false);
		  });
	  };
	  const crudErrorHandler = (error) => {
		const errors = getErrors(error);
		if (errors.allError.length > 0) {
		  message.error(errors.allError);
		} else {
			message.error("Some unknown error occured in translate API.");
		}
	  };
	const dashboardTypeDetailURL = COURSE_CONTENT_API_LIST["COURSE_DETAIL_URL"].replace("#id#", ID)
    const [breadCrumb, loadingDashboardTypeDetail, errorDashboardTypeDetail] = useFetchData(dashboardTypeDetailURL)
console.log(CONTENT_TYPE_ID?.latestUpdate);
const onUpdateVernacularId=(id)=>{
	setIsModalOpen(true)
	setCourseVernacularId(id)
  }
	return (
<>
{isModalOpen&&<TranslateModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} sourceId={courseVernacularId} courseContentId={CONTENT_TYPE_ID?.latestUpdate}/>}

		<Row>
			{error ? message.error(error) : null}
			<Col span={24}>
				<NavBreadCrumb breadCrumb={breadCrumb} courseVernacularID={extraDetails} />
				<div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
						<div className="ant-card-body">
							<h2 className="gx-mb-0">{extraDetails?.name}, Latest Updates </h2>
						</div>
					</div>
				</div>

				<Row>
					<Col span={18}>
					</Col>
					<Col span={6} style={{ textAlign: 'right' }}>
						<Link to={{ pathname: `/${dashboardType}/${ID}/vernacular/${vernacularID}/latest-update/create/`, locationData: { parent: { "name": extraDetails?.name } } }}>
							<Button type="primary">
								<span>Add New Latest Update</span>
							</Button>
						</Link>
					</Col>
				</Row>

			</Col>
			<Col span={24}>
				<LatestUpdateListComponent setExtraDetails={setExtraDetails} setError={setError} setRecordId={onUpdateVernacularId} translateIsLoading={translateIsLoading}/>

			</Col>
		</Row>
		</>
	)
};
export default LatestUpdateListing;
