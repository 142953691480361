import React, { useState, useEffect } from "react";
import { Table, Row, Col, Pagination, message, Divider } from "antd";
import { DATE_TIME_FORMAT_LISTING, OBJECT_STATUS, PAGE_SIZE } from '../../../constants/CollegeDekho'
import { getFaqList } from "../../../services/FaqList";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getTemplateContentTypeName } from "../../../util/helpers";
import { FormOutlined } from "@ant-design/icons";
import SecondaryButton from "../../../routes/courseDetail/SecondaryButton";
function findValueInObjectStatus(valueToFind) {
  for (const status of OBJECT_STATUS) {
    if (status[0] === valueToFind) {
      return status[1]; // Return the 1st index value when a match is found.
    }
  }
  return null; // Return null if the value is not found.
}
const FaqListingComponent = ({
  setExtraDetails,
  faqObjectID,
  instituteID,
  contentTypeID,
  courseID,
  page,
  setPage,
  templateContentTypeID,
  templateObjectID,
  pageContentTypeID,
  pageObjectID,
  onHanldeTranslateId
}) => {

  const [listingData, setListingData] = useState([])
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [total, setTotal] = useState(0);

  const onShowSizeChange = (current, PageSize) => setPageSize(PageSize)
  const onPageChange = (current, PageSize) => setPage(current)

  useEffect(() => {
    let mounted = true;
    setLoading(true);

      getFaqList({ templateContentTypeID, templateObjectID, pageContentTypeID, pageObjectID , pageSize, page})
      .then(data => {
        if(mounted) {
          console.log({data})
          setTotal(data?.data?.count)
          setListingData(data?.data?.results);
          setLoading(false);
          setExtraDetails(data.data.extra_details)
        }
      })
      .catch(err=> {
        console.log({err})
        message.error("Some error occured")
      });

    
  }, [ pageObjectID, page, pageSize])
  const columns=
  [
    {
      title: "ID",
      dataIndex: "id",
      visible: true,
    },
    {
      title: "Question",
      dataIndex: "question",
      visible: true,
    },
    {
      title: "Added By",
      dataIndex: "added_by",
      visible: true,
      width: 210,
      render: (_, record) => record?.added_by?.name,
    },
    {
      title: "Updated On",
      dataIndex: "updated_on",
      visible: true,
      width: 210,
      render: (text, record) => (
        <span>
          <Divider type="vertical" />
          <span>
            {" "}
            {record.updated_on
              ? moment(record.updated_on).format(DATE_TIME_FORMAT_LISTING)
              : null}{" "}
          </span>
        </span>
      ),
    },
    {
      title: "Added On ",
      dataIndex: "added_on",
      visible: true,
      width: 210,
      render: (text, record) => (
        <span>
          <Divider type="vertical" />
          <span>
            
            {record.added_on
              ? moment(record.added_on).format(DATE_TIME_FORMAT_LISTING)
              : null}
          </span>
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "object_status",
      visible: true,
      width: 120,
      render: (text, record) => (
        <span>
          <Divider type="vertical" />
          <span>{findValueInObjectStatus(record?.object_status)}</span>
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      visible: true,
      render: (text, record) => (
        <span>
          <Divider type="vertical" />
          <span className="gx-link">
            <Link
              to={
                courseID
                  ? `/${getTemplateContentTypeName(
                      templateContentTypeID
                    )}/${courseID}/vernacular/${templateObjectID}/faqs/${templateContentTypeID}/${
                      record?.id
                    }/update/?page_content_type_id=${
                      record?.content_type
                    }&page_object_id=${record?.object_id}`
                  : `/${getTemplateContentTypeName(
                      templateContentTypeID
                    )}/${templateObjectID}/faqs/${templateContentTypeID}/${
                      record?.id
                    }/update/?page_content_type_id=${
                      record?.content_type
                    }&page_object_id=${record?.object_id}`
              }
            >
              <FormOutlined />
            </Link>
          </span>
        </span>
      ),
    },
    {
      visible: courseID ? true : false,
      render: (text, record) =>
          <SecondaryButton
            onClick={() => onHanldeTranslateId(record?.id)}
            title={"Translate"}
          />
        
    },
  ].filter((col) => col.visible == true);
  return (
    <Row>
      <Col span={24}>
        <Table
          className="gx-table-responsive"
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={listingData}
          pagination={false}
          
        />
      </Col>
      <Col span={24} className="gx-mt-3 gx-text-right">
        <Pagination
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          onChange={onPageChange}
          pageSize={pageSize}
          total={total}
          current={page}
        />
      </Col>
    </Row>
  );
};

export default FaqListingComponent;
