import React, { useState } from 'react';
import { Row, Col, Form, Input, Select, Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import DebounceSelect from '../../../components/DebounceSelect'
import { getFeeFrequencyType, getCurrency, getCourseDurationType, getObjectStatus } from '../../../util/helpers';
import {
    getSpecializationList,
    getDegreeList,
    getCourseList,
    getStudyeMode,
    getNewsList,
    getExamList,
    getCareerList,
} from "../../../services/CourseDetailApi/AutoSuggestion";
import { ALLOW_IMAGE_TYPE } from '../../../constants/CollegeDekho'


const feeFrequencyType = getFeeFrequencyType()
const feeCurrencyType = getCurrency()
const durationType = getCourseDurationType()
const objectStatus = getObjectStatus()

const CourseDetailForm = () => {

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const beforeIconUpload = (file) => {

        const isJpgOrPng = ALLOW_IMAGE_TYPE.includes(file.type);
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
            return Upload.LIST_IGNORE;
        }
        return false
    }

    return (
        <>

            <Row>
                <Col span={12}>
                    <Form.Item label="Course Name" name="name" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Course name required',

                            },
                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="name" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Short Name" name="short_name" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Course short name Required',
                            },
                            { type: 'string', max: 255 }
                        ]}>
                        <Input className="gx-mb-3 gx-w-100" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="Slug" name="slug" className="labelInput"
                        rules={[
                            {
                                required: false,
                                message: 'Course slug required',

                            },
                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="slug" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Fee Range Min" name="fee_min" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Minimum fee range required',
                            },
                            { type: 'string' }
                        ]}>
                        <Input className="gx-mb-3" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Fee Range Max" name="fee_max" className="labelInput"
                        rules={[
                            {
                                required: true,
                                number: true,
                                message: 'Maximum fee range required',
                            },
                        ]}>
                        <Input className="gx-mb-3" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Fee Currency" name="average_fee_currency" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Fee currency type required',
                            },
                        ]}>
                        <Select
                            placeholder="Select Fee Currency" name="average_fee_currency" style={{ width: '100%' }} className="gx-mb-3" options={feeCurrencyType}>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Fee Frequency" name="average_fee_frequency" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Fee frequency type required',
                            },
                        ]}>
                        <Select
                            placeholder="Select Fee Frequency" name="average_fee_frequency" style={{ width: '100%' }} className="gx-mb-3" options={feeFrequencyType}>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Minimum Duration" name="min_duration" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Minimum duration required',
                            },
                            { type: 'string', max: 3 }
                        ]}>
                        <Input className="gx-mb-3" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Minimum Duration Type" name="min_duration_type" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Minimum duration type required',
                            },
                        ]}>
                        <Select
                            placeholder="Select Duration Type" name="min_duration_type" style={{ width: '100%' }} className="gx-mb-3" options={durationType}>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Maximum Duration" name="max_duration" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Maximum duration required',
                            },
                            { type: 'string', max: 3 }
                        ]}>
                        <Input className="gx-mb-3" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Maximum Duration Type" name="max_duration_type" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Maximum duration type required',
                            },
                        ]}>
                        <Select
                            placeholder="Select Duration Type" name="max_duration_type" style={{ width: '100%' }} className="gx-mb-3" options={durationType}>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Study Mode" name="study_mode" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Study Mode is required',
                            },
                        ]}>
                        <DebounceSelect
                            mode="multiple"
                            placeholder="Select Study Mode"
                            fetchOptions={getStudyeMode}
                            showSearch={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Tag Courses" name="courses" className="labelInput">
                        <DebounceSelect
                            mode="multiple"
                            placeholder="Select Course"
                            fetchOptions={getCourseList}
                            showSearch={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Tag Specialization" name="specializations" className="labelInput">

                        <DebounceSelect
                            mode="multiple"
                            placeholder="Select Specialization"
                            fetchOptions={getSpecializationList}
                            showSearch={true}
                        />

                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Tag Degree" name="degrees" className="labelInput">
                        <DebounceSelect
                            mode="multiple"
                            placeholder="Select Degree"
                            fetchOptions={getDegreeList}
                            showSearch={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Tag News" name="tagged_news" className="labelInput">
                        <DebounceSelect
                            mode="multiple"
                            placeholder="Select News"
                            fetchOptions={getNewsList}
                            showSearch={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Tag Exams" name="tagged_exams" className="labelInput">
                        <DebounceSelect
                            mode="multiple"
                            placeholder="Select Exam"
                            fetchOptions={getExamList}
                            showSearch={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Tag Careers" name="tagged_careers" className="labelInput">
                        <DebounceSelect
                            mode="multiple"
                            placeholder="Select Career"
                            fetchOptions={getCareerList}
                            showSearch={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="College Listing URL" name="college_listing_url" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'College listing url Required',
                            },
                            { type: 'string', max: 255 }
                        ]}>
                        <Input className="gx-mb-3" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Course Popularity Score" name="course_popularity_score" className="labelInput"
                        rules={[
                            {
                                required: false,
                                message: 'Course popularity score Required',
                            },
                            { type: 'string', max: 255 }
                        ]}>
                        <Input className="gx-mb-3" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Exam Listing URL" name="exam_listing_url" className="labelInput"
                        rules={[
                            {
                                required: false,
                                message: 'Exam listing url Required',
                            },
                            { type: 'string', max: 255 }
                        ]}>
                        <Input className="gx-mb-3" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Object Status" name="object_status" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Choose Object Status',
                            },
                        ]}>
                        <Select
                            placeholder="Select Object Status" name="object_status" style={{ width: '100%' }} className="gx-mb-3" options={objectStatus}>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Upload Icon"
                        className="labelInput"
                        name="icon"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                    >
                        <Upload name="logo" maxCount={1} beforeUpload={beforeIconUpload} listType="picture" className="upload-list-inline">
                            <Button icon={<UploadOutlined />}>Icon</Button>
                        </Upload>
                    </Form.Item>
                </Col>

            </Row>
        </>
    )
}

export default CourseDetailForm
