import { Button } from "antd";
import React from "react";

export default function SecondaryButton({ title, onClick }) {
  return (
    <Button type="secondary" onClick={onClick}>
      {title}
    </Button>
  );
}
