import { Checkbox, Modal, Select } from "antd";
import React, { useState } from "react";
import { getErrors, getLanguage } from "../../util/helpers";
import { CONTENT_TYPE_ID } from "../../constants/Settings";
import { post } from "../../services";
import { API_URL_LIST } from "../../constants/CollegeDekho";

export default function TranslateModal({ isModalOpen, sourceId,setIsModalOpen,courseContentId }) {
  const languageOptions = getLanguage();
  const [translateError, setTranslateError] = useState(null);
  const [translateIsLoading, setTranslateIsLoading] = useState(false);
  const [translateSuccessMessage, setTranslateSuccessMessage] = useState(null);
console.log("COURSE_CONTENT_TYPE",courseContentId);

  const [selectedValue, setSelectedValue] = useState([]);
  const handleSelectChange = (e) => {
    // const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    setSelectedValue(e);
  };
  const crudErrorHandler = (error) => {
    const errors = getErrors(error);
    if (errors.allError.length > 0) {
      setTranslateError(errors.allError);
    } else {
      setTranslateError("Some unknown error occured in translate API.");
    }
  };

  const handleApiCall = (e) => {
    setTranslateIsLoading(true);
    setTranslateError();
    if (!selectedValue) {
      setTranslateError("No language selected for translation.");
      setTranslateIsLoading(false);
      return false;
    }

    const url = API_URL_LIST.TRANSLATE_API;

    const payload = {
      target_language: selectedValue,
      source_object_id: sourceId,
      content_type_id: courseContentId,
    };

    post(url, payload)
      .then(function (response) {
        setTranslateSuccessMessage(response?.data?.message);
      })
      .catch(crudErrorHandler)
      .finally(() => {
        setTranslateIsLoading(false);
      });
  };

  const handleModalClose = (e) => {
    setIsModalOpen(false)
    setTranslateError()
    setSelectedValue([])
    setTranslateSuccessMessage()
  };
  return (
    <Modal
      title="Translate Course Page"
      visible={isModalOpen}
      okText="Translate"
      onOk={handleApiCall}
      confirmLoading={translateIsLoading}
      onCancel={handleModalClose}
    >
      <h3>Select Language</h3>
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder="Please select"
        // defaultValue={['a10', 'c12']}
        onChange={handleSelectChange}
        options={languageOptions}
        value={selectedValue}
      />
      {translateError && <div style={{ color: "red" }}>{translateError}</div>}
      {/* Display error message */}
      {translateSuccessMessage && <div>{translateSuccessMessage}</div>}
    </Modal>
  );
}
