import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { Collapse, Row, Col, Form, Button, message } from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import CourseVernacularDetailForm from './CourseVernacularDetailForm';
import { isInt, getErrors } from '../../../util/helpers';
import { useFetchData } from "../../../hooks/";
import { patchImage, postImage } from '../../../services/';

import {
    COMMON_SUCCESS_MESSAGE,
    COMMON_ERROR_MESSAGE_WRONG_DATA, COURSE_CONTENT_API_LIST
}
    from '../../../constants/CollegeDekho'

const Panel = Collapse.Panel;


const CourseVernacularDetails = () => {
    const callback = (key) => { };

    const [form] = Form.useForm();
    const history = useHistory()
    const [loader, setLoader] = useState(false);
    const { courseID, courseVernacularID } = useParams();
    const isAddMode = !isInt(courseVernacularID)
    let url = null;
    let saveURL = COURSE_CONTENT_API_LIST["COURSE_VERNACULAR_DETAIL_POST_URL"].replace("#id#", courseID)
    if (!isAddMode) {
        url = COURSE_CONTENT_API_LIST["COURSE_VERNACULAR_DETAIL_URL"].replace("#id#", courseID).replace("#id2#", courseVernacularID)
        saveURL = url
    }
    const [data, loading, error] = useFetchData(url)
    const [disabledSave, setDisabledSave] = useState(false)
    const dashboardTypeDetailURL = COURSE_CONTENT_API_LIST["COURSE_DETAIL_URL"].replace("#id#", courseID)
    const [breadCrumb, loadingDashboardTypeDetail, errorDashboardTypeDetail] = useFetchData(dashboardTypeDetailURL)

    const setCourseVernacularObj = (data) => {
        const courseVernacularObj = {
            name: data.name,
            short_name: data.short_name,
            auto_translate: data?.auto_translate ? true : false,
            content_source: data.content_source,
            object_status: data.object_status,
        }

        if (data?.language) {
            courseVernacularObj["language"] =  data.language?.id
        }

        form.setFieldsValue(courseVernacularObj)

    }
    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (data) {
                setCourseVernacularObj(data)
            }

        }

        return () => mounted = false;
    }, [data])

    const buildFormData = (formData, data, parentKey) => {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {

            Object.keys(data).forEach(key => {
                buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);
        }
    }

    const getCleanData = (data) => {
        const formData = new FormData();
        formData.append("name", data.name)
        formData.append("short_name", data.short_name)
        formData.append("auto_translate", data?.auto_translate ? true : false)
        formData.append("content_source", isAddMode?1:data.content_source)
        formData.append("object_status", data.object_status)

        if (data?.language) {
            formData.append("language", data.language)
        }

        return formData
    }

    const crudErrorHandler = (error) => {
        showError(error)


    }

    const patchData = (data) => {
        setLoader(true);
        patchImage(saveURL, data).then(function (response) {
            message.success(COMMON_SUCCESS_MESSAGE);

        })
            .catch(crudErrorHandler)
            .finally(() => {
                setDisabledSave(false)
                setLoader(false);
            });

    }
    const postData = (data) => {
        setLoader(true);
        postImage(saveURL, data)
            .then(function (response) {

                message.success(COMMON_SUCCESS_MESSAGE);
                history.push(`/course/${courseID}/vernacular/${response.data.id}/update/`)

            })
            .catch(crudErrorHandler)
            .finally(() => {
                setDisabledSave(false)
                setLoader(false);
            });

    }
    const showError = (errors) => {
        const error = getErrors(errors)
        if (error.allError.length > 0) {
            message.error(error.allError.join(" "));
        }
        else {
            message.error(COMMON_ERROR_MESSAGE_WRONG_DATA);
        }
        form.setFields(error.serverError)


    }
    const onFinish = (e) => {
        const data = form.getFieldsValue(true);
        const formData = getCleanData(data)
        setDisabledSave(true)
        if (isAddMode) {
            postData(formData)
        }
        else {
            patchData(formData)
        }
    }

    return (
        <>
            {error ? message.error(error) : loader === true ? <center><Spin /></center> :
                <Row>
                    <Col span={24}>

                        <NavBreadCrumb breadCrumb={breadCrumb} />
                        <div className="gx-pt-4">
                            <div className="ant-card ant-card-bordered gx-card-widget">
                                <div className="ant-card-body">
                                    <h2 className="gx-mb-0"> {data?.name ? `${data.name} Overview (${data?.language?.name})` : `New Vernacular Course`} </h2>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col span={24}>
                        <Form onFinish={onFinish} form={form} >
                            <Collapse defaultActiveKey={['1']} onChange={callback} className="collegeEditCollapse">
                                <Panel header="Course Vernacular Details" key="1">
                                    <div className="gx-p-2">
                                        <CourseVernacularDetailForm isAddMode={isAddMode}/>
                                    </div>
                                </Panel>
                            </Collapse>
                            <div className="d-flex">
                                <Button type="primary" htmlType="submit" disabled={disabledSave}> Save</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            }
        </>
    )
}

export default CourseVernacularDetails
