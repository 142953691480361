import React from 'react';
import { Row, Col, Form, Input, Select, Checkbox } from "antd";
import { getObjectStatus, getLanguage, getContentSource } from '../../../util/helpers';



const objectStatus = getObjectStatus()
const contentSource = getContentSource()
const languageOptions = getLanguage()

const CourseVernacularDetailForm = ({isAddMode}) => {

    return (
        <>
            <Row>
                <Col span={12}>
                    <Form.Item label="Course Name" name="name" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Course name required',

                            },
                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="name" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Short Name" name="short_name" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Course short name Required',
                            },
                            { type: 'string', max: 255 }
                        ]}>
                        <Input className="gx-mb-3 gx-w-100" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="Object Status" name="object_status" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Choose Object Status',
                            },
                        ]}>
                        <Select
                            placeholder="Select Object Status" name="object_status" style={{ width: '100%' }} className="gx-mb-3" options={objectStatus}>
                        </Select>
                    </Form.Item>
                </Col>
                {!isAddMode&&
                <Col span={12}>
                    <Form.Item label="Content Source" name="content_source" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Choose Content Source',
                            },
                        ]}>
                        <Select
                            disabled={!isAddMode}
                            placeholder="Select Content Source" name="content_source" style={{ width: '100%' }} className="gx-mb-3" options={contentSource}>
                        </Select>
                    </Form.Item>
                </Col>}

                <Col span={12}>
                    <Form.Item label="Language" name="language" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Choose Language',
                            },
                        ]}>
                        <Select
                            disabled={!isAddMode} placeholder="Select Language" name="language" style={{ width: '100%' }} className="gx-mb-3" options={languageOptions}>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label=" "
                        colon={false}
                        name="auto_translate"
                        className="labelInput"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                        valuePropName="checked"
                    >
                        <Checkbox className="gx-mb-3" name="auto_translate">Auto Translate</Checkbox>
                    </Form.Item>
                </Col>

            </Row>
        </>
    )
}

export default CourseVernacularDetailForm