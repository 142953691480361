export const API_URL_LIST = {
    "INSTITUTE_PLACEMENT_DETAIL_URL": "/api/cms/institute/#id#/placement-detail/#id1#/",
    "INSTITUTE_PLACEMENT_DETAIL_POST_URL": "/api/cms/institute/#id#/placement-detail/",
    "MASTER_PAGE_API_URL": "/api/cms/institute/master-page/",
    "MASTER_PAGE_DETAIL_URL": "/api/cms/institute/master-page/#id#/",
    "INSTITUTE_COURSE_DETAIL_URL": "/api/cms/institute/#id#/institutecourse/#id1#/",
    "INSTITUTE_COURSE_DETAIL_POST_URL": "/api/cms/institute/#id#/institutecourse/",
    "INSTITUTE_DETAIL_URL": "/api/cms/institute/#id#/",
    "INSTITUTE_DETAIL_POST_URL": "/api/cms/institute/",
    "INSTITUTE_PAGE_LISTING": "/api/cms/institute/#id#/page/",
    "INSTITUTE_PAGE_DETAIL": "/api/cms/institute/#id#/page/#id1#/",
    "INSTITUTE_FACULTY_DETAIL_URL": "/api/cms/institute/#id#/faculty/#id1#/",
    "INSTITUTE_FACULTY_DETAIL_POST_URL": "/api/cms/institute/#id#/faculty/",
    "COMPONENT_LISTING": "/api/cms/institute/component/",
    "COMPONENT_DETAIL": "/api/cms/institute/component/#id#/",
    "INSTITUTE_ALUMNI_DETAIL_URL": "/api/cms/institute/#id#/alumni/#id1#/",
    "INSTITUTE_ALUMNI_DETAIL_POST_URL": "/api/cms/institute/#id#/alumni/",
    "INSTITUTE_IMAGE_DETAIL_POST_URL": "/api/cms/institute/#id#/image/",
    "INSTITUTE_IMAGE_DETAIL_URL": "/api/cms/institute/#id#/image/#id1#/",
    "INSTITUTE_VIDEO_DETAIL_POST_URL": "/api/cms/institute/#id#/video/",
    "INSTITUTE_VIDEO_DETAIL_URL": "/api/cms/institute/#id#/video/#id1#/",
    "INSTITUTE_PAGE_COMPONENT_LISTING": "/api/cms/institute/#id#/page/#id1#/component/",
    "INSTITUTE_PAGE_COMPONENT_DETAIL": "/api/cms/institute/#id#/page/#id1#/component/#id2#/",
    "INSTITUTE_RANKING_DETAIL_URL": "/api/cms/institute/#id#/ranking/#id1#/",
    "INSTITUTE_RANKING_DETAIL_POST_URL": "/api/cms/institute/#id#/ranking/",
    "COMPONENT_AVAILABILITY": "/api/cms/institute/component-availability/",
    "COMPONENT_UPDATE_ORDER": "/api/cms/institute/#id#/page/#id1#/component/update-order/",
    "INSTITUTE_DEGREE_LISTING": "/api/cms/institute/#id#/degree/",
    "INSTITUTE_DEGREE_DETAIL": "/api/cms/institute/#id#/degree/#id#",
    "INSTITUTE_POPULAR_DEGREE_PAGE_LISTING": "/api/cms/institute/#id#/popular-degree-page/",
    "INSTITUTE_POPULAR_DEGREE_LISTING": "/api/cms/institute/#id#/popular-degree/",
    "INSTITUTE_POPULAR_DEGREE_DETAIL": "/api/cms/institute/#id#/popular-degree/#id1#/",
    "INSTITUTE_POPULAR_DEGREE_UPDATE_ORDER": "/api/cms/institute/#id#/popular-degree/update-order/",
    "INSTITUTE_FACILITY_DETAIL_POST_URL": "/api/cms/institute/#id#/facility/",
    "INSTITUTE_LATEST_UPDATE_DETAIL_URL": "/api/cms/institute/#id#/latestupdate/#id1#/",
    "INSTITUTE_LIVE_STATUS_CHANGE": "/api/cms/institute/institutelive/",
    "INSTITUTE_LATEST_UPDATE_DETAIL_POST_URL": "/api/cms/institute/#id#/latestupdate/",
    "COMMON_AUTHOR_URL": "/api/users/author/#id1#/#id2#/",
    "INSTITUTE_DEGREE_PAGE": "/api/cms/institute/#instituteID#/popular-degree/#degreeID#/page",
    "INSITUTE_DEGREE_COMPONENTS_LISING": "/api/cms/institute/#instituteID#/popular-degree/#degreeID#/page/component/?page=1&page_size=1000",
    "INSITUTE_DEGREE_PAGE_COMPONENT_DETAIL": "/api/cms/institute/#instituteID#/popular-degree/#degreeID#/page/component/#componentID#/",
    "INSTITUTE_DEGREE_COMPONENT_UPDATE_ORDER": "/api/cms/institute/#instituteID#/popular-degree/#degreeID#/page/component/update-order/",
    "INSTITUTE_DEGREE_PAGE_UPDATE": "/api/cms/institute/#instituteID#/popular-degree/#degreeID#/page/#pageID#/",
    "FAQ_LOGS": "/api/cms/common/faq/logs/",
    "FAQ_UPLOAD": "/api/cms/common/faq/upload/",
    "FAQ_CONTENT_TYPES": "/api/cms/common/faq-content-types/?template_content_type_id=#templateContentTypeID#",
    "FAQ_OBJECT_LIST": "/api/cms/common/object-list/?page_content_type_id=#pageContentTypeID#&template_object_id=#templateObjectID#",
    "FAQ_GET_PATCH": "/api/cms/common/faq/#pageContentTypeID#/#pageObjectID#/#faqObjectID#/",
    "FAQ_POST": "/api/cms/common/faq/#pageContentTypeID#/#pageObjectID#/",
    "INSITUTE_COURSE_COMPONENTS_LISING": "/api/cms/institute/#instituteID#/institute-course/#courseID#/page/component/",
    "INSITUTE_COURSE_PAGE_COMPONENT_DETAIL": "/api/cms/institute/#instituteID#/institute-course/#courseID#/page/component/#componentID#/",
    "INSTITUTE_COURSE_PAGE_COMPONENT_UPDATE_ORDER": "/api/cms/institute/#instituteID#/institute-course/#courseID#/page/component/update-order/",
    "INSTITUTE_COURSE_PAGE_UPDATE": "/api/cms/institute/#instituteID#/institute-course/#courseID#/page/#pageID#/",
    "INSTITUTE_COURSE_PAGE": "/api/cms/institute/#instituteID#/institute-course/#courseID#/page/",
    "STUDENT_PLACEMENT_DETAIL_POST_URL":
    "/api/cms/institute/#instituteID#/placement-type/#placementID#/student-placement/",
  "STUDENT_PLACEMENT_DETAIL":
    "/api/cms/institute/#instituteID#/placement-type/#placementID#/student-placement/#studentPlacementID#/",
  "SECTOR_PLACEMENT_DETAIL_POST_URL":
    "/api/cms/institute/#instituteID#/placement-type/#placementID#/sector-placement/",
  "SECTOR_PLACEMENT_DETAIL":
    "/api/cms/institute/#instituteID#/placement-type/#placementID#/sector-placement/#studentPlacementID#/",
  "COMPANY_PLACEMENT_DETAIL_POST_URL":
    "/api/cms/institute/#instituteID#/placement-type/#placementID#/company-placement/",
  "COMPANY_PLACEMENT_DETAIL":
    "/api/cms/institute/#instituteID#/placement-type/#placementID#/company-placement/#studentPlacementID#/",
    "TRANSLATE_API": "/api/translation/translate",

}

export const COURSE_CONTENT_API_LIST = {
    "COURSE_DETAIL_URL": "/api/cms/course-details/course-content/#id#/",
    "COURSE_DETAIL_POST_URL": "/api/cms/course-details/course-content/",
    "COURSE_VERNACULAR_DETAIL_URL": "/api/cms/course-details/course-content/#id#/vernacular/#id2#/",
    "COURSE_VERNACULAR_DETAIL_POST_URL": "/api/cms/course-details/course-content/#id#/vernacular/",
    "MASTER_PAGE_API_URL": "/api/cms/course-details/course-master-page/",
    "MASTER_PAGE_DETAIL_URL": "/api/cms/course-details/course-master-page/#id#/",
    "COMPONENT_LISTING": "/api/cms/course-details/course-component/",
    "COMPONENT_DETAIL": "/api/cms/course-details/course-component/#id#/",
    "COURSE_PAGE_LISTING": "/api/cms/course-details/course-content/#id#/page/",
    "COURSE_PAGE_DETAIL": "/api/cms/course-details/course-content/#id#/page/#id1#/",
    "COURSE_PAGE_COMPONENT_LISTING": "/api/cms/course-details/course-content/#id#/page/#id1#/component/?page=1&page_size=1000",
    "COURSE_PAGE_COMPONENT_DETAIL": "/api/cms/course-details/course-content/#id#/page/#id1#/component/#id2#/",
    "COMPONENT_UPDATE_ORDER": "/api/cms/course-details/course-content/#id#/page/#id1#/component/update-order/",
    "COMPONENT_AVAILABILITY": "/api/cms/course-details/course-content/component-availability/",

}

export const SCHOLARSHIP_API_LIST = {
    "SCHOLARSHIP_DETAIL_PAGE_URL": "/api/cms/scholarship/#id#/",
    "SCHOLARSHIP_PAGE_COMPONENT_LISTING": "/api/cms/scholarship/#id#/component/",
    "SCHOLARSHIP_PAGE_COMPONENT_DETAIL": "/api/cms/scholarship/#id#/component/#id1#/",
    "SCHOLARSHIP_COMPONENT_UPDATE_ORDER": "/api/cms/scholarship/#id#/component/update-order/"
}


export const SIDE_BAR_API_LIST = {
    MAIN_API: "/api/cms/common/sidebar-menu/" //?template_content_type_id=
}
export const COMMON_API_LIST = {
    "LATEST_UPDATE_DETAIL_URL": "/api/cms/common/latest-update/#id#/#id1#/#id2#/",
    "LATEST_UPDATE_DETAIL_POST_URL": "/api/cms/common/latest-update/#id#/#id1#/",
    "PAGES_AND_WIDGETS_LIST_URL": "/api/cms/common/widget-list/#id#/#id1#",
    "SLUG_REDIRECTION_POST_URL": "/api/cms/common/slug-redirection/#id#/",
    "SLUG_REDIRECTION_DETAIL_URL": "/api/cms/common/slug-redirection/#id#/#id1#/",
}

export const CONTENT_LOG_API_LIST = {
    "DEFAULT_LOG_COMPARE_URL": "/api/#id#/#id1#/compare",
    "LOG_VERSION_COMPARE_URL": "/api/#id#/#id1#/compare/#id2#/#id3#",
    "LOG_LISTING_URL": "/api/logs/#id#/#id1#"
}

export const IMAGE_UPLOAD_URL = "/api/cms/common/image-upload/"
export const PAGE_SIZE = 10
export const COMMON_ERROR_MESSAGE = "Some error occurred"
export const COMMON_SUCCESS_MESSAGE = "Data saved"
export const COMMON_ERROR_MESSAGE_WRONG_DATA = "Data Not Saved"
export const DATE_TIME_FORMAT_LISTING = "Do MMM YY, h:mm a"
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss"
export const DATE_FORMAT = "YYYY-MM-DD"
export const PARTNER_STATUS = [
    [1, "Partner"],
    [2, "CAF Partner"],
    [3, "Branding Partner"]
]
export const ADMISSION_STATUS = [
    [1, 'Live'],
    [2, 'Pause']
]
export const INSTITUTE_TYPE = [[1, 'Private'],
[2, 'Government'],
[3, 'Public'], [4, 'Community'],
[5, 'Private UnAided'],
[6, 'Private Aided'],
[7, 'Government Aided'],
[8, 'University Managed'],
[9, 'Deemed University Private'],
[10, 'Deemed University Government'],
[11, 'University Managed Government'],
[12, 'University Managed Private'],
[13, 'Central University'],
[14, 'Private [Autonomous]'],
[15, 'State University'],
[16, 'Public [Autonomous]'],
[17, 'Deemed to be University'],
[18, 'Autonomous University'],
[19, 'Public Institute'],
[20, 'Open University'],
[21, 'Deemed University'],
[22, 'Public [State University]'],
[23, 'Distance Education'],
[24, 'Private [State University]'],
[25, 'Local Body'],
[26, 'University'],
[27, "Government Aided/ Self Finance"]]

export const INSTITUTION_TYPE = [
    [1, "Institute"],
    [2, "University"],
]


export const GENDER = [["M", "Male"], ["F", "Female"], ["CO", "Coed"]]
export const LEVEL = [
    [1, 'UG'],
    [2, 'PG'],
    [3, 'Diploma'],
    [4, 'Ph.D'],
    [5, 'Certificate'],
    [7, '10th'],
    [8, '12th'],
]


export const SERVICES = [
    [1, 'GENERAL'],
    [2, 'ETP'],
]

export const COURSE_MODE = [
    [1, 'Regular'],
    [2, 'Part Time'],
    [4, 'Distance'],
    [5, 'Online'],
]

export const DURATION_TYPE = [
    [1, 'Minutes'],
    [2, 'Hours'],
    [3, 'Days'],
    [4, 'Weeks'],
    [5, 'Months'],
    [6, 'Years'],
]

export const COURSE_DURATION_TYPE = [
    [1, 'Hours'],
    [2, 'Days'],
    [3, 'Weeks'],
    [4, 'Months'],
    [5, 'Years'],
]

export const FEE_TYPE = [
    [1, 'Per Semester'],
    [2, 'Annually'],
]



export const COURSE_TYPE = [
    [1, 'Semester'],
    [2, 'Annual'],
    [3, 'Trimester']
]

export const COURSE_ATTRIBUTE = [
    [1, 'Honors'],
    [2, 'Lateral Entry'],
    [3, 'Lateral Entry with Hons'],
    [4, 'Evening Class'],
    [5, 'Evening Class Honors'],
    [6, 'Evening Class Lateral Entry'],
    [7, 'Evening Class Lateral Entry With Hons']
]
export const CURRENCY = [[1, "INR"],
[2, "USD"],
[3, "Canadian Dollar"],
[4, "New Zealand Dollar"],
[5, "Australia Dollar"],
[6, "Russia Ruble"],
[7, "Ukraine Hryvnia"],
[8, "United Kingdom Pound"],
[9, "Albania Lek"],
[10, "Thailand Baht"],
[11, "Switzerland Franc"],
[12, "Sweden Krona"],
[13, "Singapore Dollar"],
[14, "Romania New Leu"],
[15, "Poland Zloty"],
[16, "Philippines Peso"],
[17, "Norway Krone"],
[18, "Malaysia Ringgit"],
[19, "Macedonia Denar"],
[20, "Euro Member Countries - Euro"],
[21, "Hungary Forint"],
[22, "Denmark Krone"],
[23, "Czech Republic Koruna"],
[24, "Bulgaria Lev"],
[25, "Japanese Yen"],
[26, "South Africa Rand"]

]

export const SHIFT = [
    [1, 'Morning'],
    [2, 'Afternoon'],
    [3, 'Evening']
]

export const PROGRAM = [
    [1, 'General'],
    [2, 'ETP']
]

export const OBJECT_STATUS = [
    [1, 'Active'],
    [0, 'Inactive'],
    [-1, 'Soft Delete'],
]
export const COURSE_ADMISSION_STATUS = [
    [1, 'Partner'],
    [2, 'Partner-Seat filled']
]

const COMPONENT_CREATED_BY_CONTENT_TEAM = 1
const HIGHLIGHTS = 2
const TABLE_OF_CONTENT = 3
const LATEST_UPDATE = 4
const AD_SLOT_1 = 5
const DEGREE_LIST = 6
const AD_SLOT_2 = 7
const GALLERY = 8
const FACILITIES = 9
const AD_SLOT_3 = 10
const CTA_GET_FREE_COUNSELLING = 11
const ALUMNI = 12
const FACULTY = 13
const CTA_DOWNLOAD_BROCHURE = 14
const RANKINGS = 15
const REVIEWS = 16
const RELATED_QUESTIONS = 17
const CTA_POST_A_QUESTION = 18
const FAQS = 19
const AD_SLOT_4 = 20
const CONTACT_MAP = 21
const CTA_ADMISSION_OPEN_WIDGET = 22
const IN_LINK_RELATED_NEWS = 23
const AD_SLOT_5 = 24
const IN_LINK_EXPLORE_NEARBY_COLLEGE = 25
const SIMILAR_COLLEGES = 26
const TOP_COURSES = 27
const IN_LINK_FEE_DETAILS_OF_POPULAR_COLLEGES = 28
const IN_LINK_FOOTER_EXPLORE_COLLEGES_BY_SPECIALIZATION_BY_DEGREE_BY_COURSE = 29
const AD_SLOT_6 = 30
const FILTERS = 31
const PICTURES = 32
const VIDEOS = 33
const COLLEGE_NEWS = 34
const RELATED_NEWS = 35
const OVERALL_REVIEW_RATING = 36
const REVIEWS_SORTING = 37
const REVIEW_LIST = 38
const CUTOFF_LIST = 39
const IN_LINK_EDUCATION_LOAN_CTA = 40
const AFFILIATED_COLLEGES = 41

export const COMPONENT = [
    [COMPONENT_CREATED_BY_CONTENT_TEAM, 'COMPONENT_CREATED_BY_CONTENT_TEAM'],
    [HIGHLIGHTS, 'HIGHLIGHTS'],
    [TABLE_OF_CONTENT, 'TABLE_OF_CONTENT'],
    [LATEST_UPDATE, 'LATEST_UPDATE'],
    [AD_SLOT_1, 'AD_SLOT_1'],
    [DEGREE_LIST, 'DEGREE_LIST'],
    [AD_SLOT_2, 'AD_SLOT_2'],
    [GALLERY, 'GALLERY'],
    [FACILITIES, 'FACILITIES'],
    [AD_SLOT_3, 'AD_SLOT_3'],
    [CTA_GET_FREE_COUNSELLING, 'CTA_GET_FREE_COUNSELLING'],
    [ALUMNI, 'ALUMNI'],
    [FACULTY, 'FACULTY'],
    [CTA_DOWNLOAD_BROCHURE, 'CTA_DOWNLOAD_BROCHURE'],
    [RANKINGS, 'RANKINGS'],
    [REVIEWS, 'REVIEWS'],
    [RELATED_QUESTIONS, 'RELATED_QUESTIONS'],
    [CTA_POST_A_QUESTION, 'CTA_POST_A_QUESTION'],
    [FAQS, 'FAQS'],
    [AD_SLOT_4, 'AD_SLOT_4'],
    [CONTACT_MAP, 'CONTACT_MAP'],
    [CTA_ADMISSION_OPEN_WIDGET, 'CTA_ADMISSION_OPEN_WIDGET'],
    [IN_LINK_RELATED_NEWS, 'IN_LINK_RELATED_NEWS'],
    [AD_SLOT_5, 'AD_SLOT_5'],
    [IN_LINK_EXPLORE_NEARBY_COLLEGE, 'IN_LINK_EXPLORE_NEARBY_COLLEGE'],
    [SIMILAR_COLLEGES, 'SIMILAR_COLLEGES'],
    [TOP_COURSES, 'TOP_COURSES'],
    [IN_LINK_FOOTER_EXPLORE_COLLEGES_BY_SPECIALIZATION_BY_DEGREE_BY_COURSE, 'IN_LINK_FOOTER_EXPLORE_COLLEGES_BY_SPECIALIZATION_BY_DEGREE_BY_COURSE'],
    [AD_SLOT_6, 'AD_SLOT_6'],
    [FILTERS, 'FILTERS'],
    [PICTURES, 'PICTURES'],
    [VIDEOS, 'VIDEOS'],
    [COLLEGE_NEWS, 'COLLEGE_NEWS'],
    [RELATED_NEWS, 'RELATED_NEWS'],
    [OVERALL_REVIEW_RATING, 'OVERALL_REVIEW_RATING'],
    [REVIEWS_SORTING, 'REVIEWS_SORTING'],
    [REVIEW_LIST, 'REVIEW_LIST'],
    [CUTOFF_LIST, 'CUTOFF_LIST'],
    [IN_LINK_FEE_DETAILS_OF_POPULAR_COLLEGES, 'IN_LINK_FEE_DETAILS_OF_POPULAR_COLLEGES'],
    [IN_LINK_EDUCATION_LOAN_CTA, 'IN_LINK_EDUCATION_LOAN_CTA'],
    [AFFILIATED_COLLEGES, 'AFFILIATED_COLLEGES']
]

const PAGE_TYPE_OVERVIEW = 1
const PAGE_TYPE_COURSES = 2
const PAGE_TYPE_ADMISSION = 3
const PAGE_TYPE_SCHOLARSHIP = 4
const PAGE_TYPE_PLACEMENTS = 5
const PAGE_TYPE_CUTOFF = 6
const PAGE_TYPE_FACULTY = 7
const PAGE_TYPE_CAMPUS = 8
const PAGE_TYPE_HOSTEL = 9
const PAGE_TYPE_GALLERY = 10
const PAGE_TYPE_REVIEWS = 11
const PAGE_TYPE_QNA = 12
const PAGE_TYPE_NEWS = 13

export const PAGE_TYPE = [
    [PAGE_TYPE_OVERVIEW, 'OVERVIEW'],
    [PAGE_TYPE_COURSES, 'COURSES'],
    [PAGE_TYPE_ADMISSION, 'ADMISSION'],
    [PAGE_TYPE_SCHOLARSHIP, 'SCHOLARSHIP'],
    [PAGE_TYPE_PLACEMENTS, 'PLACEMENTS'],
    [PAGE_TYPE_CUTOFF, 'CUTOFF'],
    [PAGE_TYPE_FACULTY, 'FACULTY'],
    [PAGE_TYPE_CAMPUS, 'CAMPUS'],
    [PAGE_TYPE_HOSTEL, 'HOSTEL'],
    [PAGE_TYPE_GALLERY, 'GALLERY'],
    [PAGE_TYPE_REVIEWS, 'REVIEWS'],
    [PAGE_TYPE_QNA, 'QNA'],
    [PAGE_TYPE_NEWS, 'NEWS'],
]


const PRIMARY = 1
const BANNER = 2
const STUDENT = 3
const INFRA = 4
const OTHER = 5
const HOSTEL = 6
const MESS = 7
const CLASSROOM = 8
const LABORATORIES = 9
const GROUND = 10
const LIBRARY = 11
const GYM = 12
const CULTURAL_ACTIVITY = 13
const INSTITUTE_EVENT = 14
const FACULTY_ROOM = 15
const AWARDS = 16
const SPECIAL_GUEST = 17
const CAFETERIA = 18
const COMPUTER_LAB = 19
const AUDITORIUM = 20
const CONFERENCE_ROOM = 21
const SWIMMING_POOL = 22
const TRANSPORTATION = 23
const GIRLS_HOSTEL = 24
const BOYS_HOSTEL = 25
const STUDIO = 26
const VIDEO_STUDIO = 27
const AUDIO_STUDIO = 28
const PHOTOGRAPHY_LAB = 29
const NEWS_ROOM = 30
const KITCHEN = 31
const BUILDING = 32
const MAIN_BUILDING = 33
const CONVOCATION = 34
const WORKSHOP = 35
const MAIN_ENTRANCE = 36
const SEMINAR_HALL = 37
const LOBBY = 38
const HOD_ROOM = 39
export const IMAGE_TYPE_CHOICES = [
    [PRIMARY, 'Primary'],
    [BANNER, 'Banner'],
    [STUDENT, 'Student'],
    [INFRA, 'Infra'],
    [OTHER, 'Other'],
    [HOSTEL, 'Hostel'],
    [MESS, 'Mess'],
    [CLASSROOM, 'Classroom'],
    [LABORATORIES, 'Laboratories'],
    [GROUND, 'Ground'],
    [LIBRARY, 'Library'],
    [GYM, 'Gym'],
    [CULTURAL_ACTIVITY, 'Cultural Activity'],
    [INSTITUTE_EVENT, 'Institute Event'],
    [FACULTY_ROOM, 'Faculty Room'],
    [AWARDS, 'Awards'],
    [SPECIAL_GUEST, 'Special Guest'],
    [CAFETERIA, 'Cafeteria'],
    [COMPUTER_LAB, 'Computer Lab'],
    [LABORATORIES, 'Laboratories'],
    [AUDITORIUM, 'Auditorium'],
    [CONFERENCE_ROOM, 'Conference Room'],
    [SWIMMING_POOL, 'Swimming Pool'],
    [TRANSPORTATION, 'Transportation'],
    [GIRLS_HOSTEL, 'Girls Hostel'],
    [BOYS_HOSTEL, 'Boys Hostel'],
    [STUDIO, 'Studio'],
    [VIDEO_STUDIO, 'Video Studio'],
    [AUDIO_STUDIO, 'Audio Studio'],
    [PHOTOGRAPHY_LAB, 'Photography Lab'],
    [NEWS_ROOM, 'News Room'],
    [KITCHEN, 'Kitchen'],
    [BUILDING, 'Building'],
    [MAIN_BUILDING, 'Main Building'],
    [CONVOCATION, 'Convocation'],
    [WORKSHOP, 'Workshop'],
    [MAIN_ENTRANCE, 'Main Entrance'],
    [SEMINAR_HALL, 'Seminar Hall'],
    [LOBBY, 'Lobby'],
    [HOD_ROOM, 'HOD Room']
]

const STUDENT_TESTIMONIAL = 1
const CAMPUS_AND_INFRA = 2
const HOSTEL_FACILITIES = 3
const HOSTEL_BUILDING = 4
const VIDEO_MESS = 5
const VIDEO_CLASSROOM = 6
const VIDEO_LABORATORIES = 7
const VIDEO_LIBRARY = 8
const VIDEO_GROUND = 9
const SPORTS_EVENTS = 10
const VIDEO_GYM = 11
const CAMPUS_FACILITIES = 12
const EXTRA_CURRICULAR_EVENTS = 13
const FACULTY_TESTIMONIALS = 14
const LIFE_AT_CAMPUS = 15
const CORPORATE_VIDEO = 16
const VIDEO_OTHER = 17
export const VIDEO_TYPE_CHOICES = [
    [STUDENT_TESTIMONIAL, 'Student Testimonial'],
    [CAMPUS_AND_INFRA, 'Campus And Infra'],
    [HOSTEL_FACILITIES, 'Hostel Facilities'],
    [HOSTEL_BUILDING, 'Hostel Building'],
    [VIDEO_MESS, 'Mess'],
    [VIDEO_CLASSROOM, 'Classroom'],
    [VIDEO_LABORATORIES, 'Laboratories'],
    [VIDEO_LIBRARY, 'Library'],
    [VIDEO_GROUND, 'Ground'],
    [SPORTS_EVENTS, 'Sports Events'],
    [VIDEO_GYM, 'Gym'],
    [CAMPUS_FACILITIES, 'Campus Facilities'],
    [EXTRA_CURRICULAR_EVENTS, 'Extra Curricular Events'],
    [FACULTY_TESTIMONIALS, 'Faculty Testimonials'],
    [LIFE_AT_CAMPUS, 'Life At Campus'],
    [CORPORATE_VIDEO, 'Corporate Video'],
    [VIDEO_OTHER, 'Other']
]

export const NO_IMAGE_AVAILABLE = "/assets/images/no-image-available.png"
export const ALLOW_IMAGE_TYPE = ["image/jpeg", "image/png"]
export const ALLOW_DOCUMENT_TYPE = ["image/jpeg", "image/png", "application/pdf"]

export const FEE_FREQUENCY_TYPE = [
    [1, "Semester"],
    [2, "Annual"],
    [3, "One Time"],
]

export const COURSE_COMPONENT_LIST = [
    [1, "STATIC"],
    // [2, "HIGHLIGHTS"],
    [3, "TABLE_OF_CONTENT"],
    [4, "LATEST_UPDATE"],
    [5, "POPULAR_COURSES"],
    // [6, "POPULAR_LIST_OF_ALL_COURSES"],
    // [7, "POPULAR_DEGREES"],
    [8, "JOB_ASSURED_CTA"],
    [9, "AD_SLOT_1"],
    [10, "POPULAR_DEGREE_COLLEGES"],
    [11, "AD_SLOT_2"],
    [12, "DEGREE_COLLEGES_BY_COLLEGE_TYPE"],
    [13, "AD_SLOT_3"],
    [14, "FAQS"],
    [15, "RELATED_QUESTIONS"],
    [16, "ASK_A_QUESTION_CTA"],
    [17, "AD_SLOT_4"],
    [18, "RELATED_NEWS"],
    [19, "RELATED_EXAMS"],
    [20, "GET_COUNSELING_CTA"],
    [21, "DEGREE_COLLEGES_IN_STATES"],
    [22, "DEGREE_COLLEGES_IN_CITIES"],
    [23, "SUBSCRIBE_NOW_CTA"],
    [24, "PROMOTED_COLLEGES"],
    [25, "COURSE_NEWS"],
    [26, "REQUEST_A_CALLBACK"],
]

export const COURSE_MASTER_PAGE_LIST = [
    [1, "OVERVIEW"],
    [2, "SYLLABUS"],
    // [3, "COURSES"],
    [4, "JOBS"],
    [5, "ADMISSION_PROCESS"],
    [6, "NEWS"],
]

export const YES_NO_TYPE = [
    [0, "NO"],
    [1, "YES"],
]

export const USER_GROUP = ['CollegeAdmin', 'CMSAdmin', 'ChiefEditorNews']

export const LOG_CONTENT_TYPE = {
    1: "News",
    2: "Exam Widgets",
    3: "CLD_INSTITUTE_COURSE",
    4: "Institute Page Components",
    5: "Article",
    6: "Board",
    7: "Blog",
    8: "Course Page Components",
}

export const SCHOLARSHIP_COMPONENT_LIST = [
    [1, "STATIC"],
]

export const COURSE_STUDY_MODE = [
    [1, 'Regular'],
    [2, 'Part-time'],
    [3, 'Distance Learning'],
]


export const LANGUAGES = [
    [1, 'English'],
    [2, 'Hindi'],
    [3, 'Telugu'],
    [4, 'Bengali'],
    [5, 'Gujarati'],
    [6, 'Punjabi'],
    [7, 'Kannada'],
    [8, 'Marathi'],
    [9, 'Odia'],
    [10, 'Malayalam'],
    [11, 'Tamil']
]

export const SIZE_TYPE = [
    [1, "Acres"],
    [2, "Square Meters"],
    [3, "Square Feet"],
]

export const CONTENT_SOURCE = [
    [1, 'Manual'],
    [2, 'Automatic'],
]
